import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
import throttle from 'lodash/throttle'
import Breakpoints from '../../constants/Breakpoints'
import { getElementsByTagNames } from '../../utils/dom'
import withBreakpoint from '../../utils/hoc/withBreakpoint'
import useViewportDimensions from '../../utils/hooks/useViewportDimensions'
import styles from './BlogPostNav.module.css'

function getDotStates(headings, scrollTop, viewportHeight) {
  return headings.map(({ rect }) => ({
    active: (scrollTop + viewportHeight) > rect.top,
  }))
}

function getLineStates(headings, scrollTop, viewportHeight) {
  return headings.map(({ rect }, index) => {
    if (index === 0) return { percentage: 0 }
    if ((scrollTop + viewportHeight) <= headings[index-1].rect.top) return { percentage: 0 }
    if ((scrollTop + viewportHeight) > rect.top) return { percentage: 1 }

    const distanceBetweenHeadings = rect.top - headings[index-1].rect.top
    const percentage = ((scrollTop + viewportHeight) - headings[index-1].rect.top) / distanceBetweenHeadings

    return { percentage }
  })
}

function BlogPostNav({ blogPostContentRef }) {
  const [headings, setHeadings] = useState()
  const [scrollTop, setScrollTop] = useState(window.pageYOffset)
  const { viewportHeight } = useViewportDimensions()

  const handleScroll = useCallback(throttle(() => {
    setScrollTop(window.pageYOffset)
  }, 200), [])

  useEffect(() => {
    const headingEls = [
      document.querySelector('h1'), // Rendered by Layout.js
      ...getElementsByTagNames(blogPostContentRef.current, 'h2', 'h3', 'h4')
    ]

    setHeadings(
      headingEls.map(headingEl => ({
        key: headingEl.id,
        text: headingEl.textContent,
        rect: headingEl.getBoundingClientRect(),
      }))
    )

    document.addEventListener('scroll', handleScroll, { passive: true })
    return () => document.removeEventListener('scroll', handleScroll, { passive: true })
  }, [])

  if (!headings) return null

  const dotStates = getDotStates(headings, scrollTop, viewportHeight)
  const lineStates = getLineStates(headings, scrollTop, viewportHeight)

  return (
    <div className={styles.container}>
      {headings.map(({ key }, index) => (
        <div key={key}>
          {index === 0 ? null : (
            <div className={styles.line}>
              <div
                className={styles.lineInner}
                style={{ height: `${20 * lineStates[index].percentage}px` }}
              />
            </div>
          )}
          <div className={classnames(
            styles.dot,
            { [styles.active]: dotStates[index].active }
          )}/>
        </div>
      ))}
    </div>
  )
}

export default withBreakpoint(BlogPostNav, Breakpoints.DESKTOP)
