import { useCallback, useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

function getViewportDimensions() {
  return {
    viewportHeight: window.innerHeight,
    viewportWidth: window.innerWidth,
  }
}

export default function useViewportDimensions() {
  const [viewportDimensions, setViewportDimensions] = useState(getViewportDimensions())

  const handleResize = useCallback(throttle(() => {
    setViewportDimensions(getViewportDimensions())
  }, 200), [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return viewportDimensions
}
