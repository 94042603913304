import React from 'react'
import useViewportDimensions from '../hooks/useViewportDimensions'

export default function withBreakpoint(WrappedComponent, breakpoint) {
  return (props) => {
    const { viewportWidth } = useViewportDimensions()
    if (viewportWidth < breakpoint) return null;
    return <WrappedComponent {...props}/>
  }
}
