import React from 'react'
import styles from './HackerNewsShareButton.module.css'

function HackerNewsShareButton({ url, title }) {
  const height = 275
  const width = 775

  function handleClick() {
    const config = {
      centerscreen: 'yes',
      chrome: 'yes',
      directories: 'no',
      location: 'no',
      menubar: 'no',
      resizable: 'no',
      scrollbars: 'yes',
      status: 'no',
      toolbar: 'no',
      height,
      width,
      left: (window.outerWidth / 2) + (window.screenX || window.screenLeft || 0) - (width / 2),
      top: (window.outerHeight / 2) + (window.screenY || window.screenTop || 0) - (height / 2)
    }

    window.open(
      `https://news.ycombinator.com/submitlink?u=${encodeURIComponent(url)}&t=${encodeURIComponent(title)}`,
      '',
      Object.keys(config).map(key => `${key}=${config[key]}`).join(', ')
    )
  }

  return (
    <button
      className={styles.button}
      onClick={handleClick}
    >
      Y
    </button>
  )
}

export default HackerNewsShareButton
