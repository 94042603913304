import React, { useRef } from 'react'
import { Link, graphql } from 'gatsby'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import ShareButton from '../components/ShareButton'
import Floatie from '../components/Floatie'
import BlogPostNav from '../components/BlogPostNav'
import { rhythm, scale } from '../utils/typography'
import styles from './blog-post.module.css'

function BlogPostTemplate({ data, pageContext, location }) {
  const blogPostContentRef = useRef()
  const post = data.markdownRemark
  const { frontmatter } = post;
  const { previous, next } = pageContext
  const seoImageUrl = `${location.origin}${frontmatter.thumbnailImage.childImageSharp.fixed.src}`
  const title = frontmatter.title
  const description = frontmatter.description || post.excerpt
  const url = location.href

  return (
    <>
      <Layout location={location} title={frontmatter.title}>
        <SEO
          title={title}
          description={description}
          keywords={frontmatter.keywords}
          url={url}
          type='article'
          image={seoImageUrl}
        />
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {frontmatter.date}
        </p>
        <div
          ref={blogPostContentRef}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
      <Floatie className={styles.shareButtonFloatie}>
        <ShareButton
          title={title}
          url={url}
        />
      </Floatie>
      <Floatie className={styles.blogPostNavFloatie}>
        <BlogPostNav blogPostContentRef={blogPostContentRef}/>
      </Floatie>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        keywords
        thumbnailImage {
          childImageSharp {
            fixed(width: 1200, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        description
      }
    }
  }
`
