import React, { useEffect, useRef, useState } from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import classnames from 'classnames'
import HackerNewsShareButton from '../HackerNewsShareButton'
import shareIconSrc from './share.svg'
import styles from './ShareButton.module.css'


function ShareButton({ url, title }) {
  const [isOpen, setIsOpen] = useState(false)
  const shareBtn = useRef()

  const isWebShareSupported = !!navigator.share

  useEffect(() => {
    function handleDocumentClick(e) {
      if (e.target === shareBtn.current || (shareBtn.current && shareBtn.current.contains(e.target))) {
        if (isWebShareSupported) {
          navigator.share({ url, title })
        } else {
          setIsOpen(x => !x)
        }

        return
      }

      setIsOpen(false)
    }

    document.addEventListener('touchend', handleDocumentClick)
    return () => {
      document.removeEventListener('touchend', handleDocumentClick)
    }
  }, []);

  function handleMouseEnter() {
    if (!isWebShareSupported) {
      setIsOpen(true)
    }
  }

  function handleMouseLeave() {
    setIsOpen(false)
  }

  return (
    <div
      className={classnames(styles.container, { [styles.open]: isOpen })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classnames(styles.socialButtonsContainer, { [styles.open]: isOpen })}>
        <EmailShareButton url={url} subject={title}>
          <EmailIcon size={37}/>
        </EmailShareButton>
        <HackerNewsShareButton url={url} title={title}/>
        <RedditShareButton url={url} title={title}>
          <RedditIcon size={37}/>
        </RedditShareButton>
        <FacebookShareButton url={url} quote={title}>
          <FacebookIcon size={37}/>
        </FacebookShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={37}/>
        </LinkedinShareButton>
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon size={37}/>
        </TwitterShareButton>
      </div>
      <button
        className={styles.shareButton}
        ref={shareBtn}
      >
        <img
          className={styles.shareIcon}
          src={shareIconSrc}
        />
      </button>
    </div>
  )
}

export default ShareButton
