import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

function Floatie({ children, className }) {
  const [portalNode, setPortalNode] = useState()

  useEffect(() => {
    const node = document.createElement('div')
    document.body.appendChild(node)

    setPortalNode(node)

    return () => {
      document.body.removeChild(node)
    }
  }, [])

  useEffect(() => {
    if (!className || !portalNode) return
    portalNode.classList.add(className)
    return () => portalNode.classList.remove(className)
  }, [className, portalNode])

  return portalNode ? createPortal(children, portalNode) : null
}

export default Floatie
